<template>
  <router-link :to="path">
    <v-container style="padding:0px" grid-list-xs fluid>
      <v-layout
        align-center
        justify-center
        row
        class="px-1"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card class="rounded-card"
                  :elevation="hover ? 9:2"
                  height="auto"
                  max-width="400px"
                  align="center"
                  justify="center"
                  contain
          >
            <h3 style="color:black;" class=" font-weight-bold mt-3 text-center">
              {{ headline }}
            </h3>
            <h4 style="color:black;" class=" font-weight-bold mt-2 text-center">
              {{ description }}
            </h4>
            <v-divider style="color:black;" class="mt-3" />
            <v-img
              class="mt-2 mb-2"
              :src="img"
              contain
            />
          </v-card>
        </v-hover>
      </v-layout>
    </v-container>
  </router-link>
</template>
<script>
export default {
  props: {
    headline: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    img: {
      type: String,
      default: '',
      required: false
    },
    path: {
      type: String,
      default: '',
      required: true
    },
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/screen-sizes.scss';
.rounded-card {
  margin-top:auto;
  margin-bottom:auto;
  @include respond-to(less-smallest-screens) {height:52vw; width:30vw; border-radius: 4.5px; }
  @include respond-to(smallest-screens) { height:40vw; width:28vw; border-radius: 4.5px; }
  @include respond-to(small-screens) { height:44vw; width:29vw;border-radius: 5.5px;}
  @include respond-to(medium-screens) { height:42vw; width:28vw;border-radius: 6.5px;}
  @include respond-to(large-screens) { height: 32vw; width:22vw;border-radius: 7.5px;}
  @include respond-to(largest-screens) { height:auto; width:15vw; border-radius: 8.5px;}
}
.thumbnail {
  display: inline-block;
  max-width: 100%; /* only this one important */
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }
.imgC{
  justify:center;
  align:center;
  position:relative;
  @include respond-to(less-smallest-screens) {height:60px; top:5px;}
  @include respond-to(smallest-screens) {height:60px;top:6.5px;}
  @include respond-to(small-screens) {height:70px;top:10px;}
  @include respond-to(medium-screens) {height:80px;top:12px;}
  @include respond-to(large-screens) {height: 140px;top:12px;}
  @include respond-to(largest-screens) {height:150px;top:15px;}
  padding-bottom:20px;
  padding-top:30px;
  }
.subjects{
  text-decoration: none;
  font-family: Oswald;
  display: inline-block;
  text-align:center;
  position:relative;
  @include respond-to(less-smallest-screens) {font-size:2vw;padding-top:15px}
  @include respond-to(smallest-screens) {font-size:2vw;padding-top:15px}
  @include respond-to(small-screens) {font-size:3vw;padding-top:20px;}
  @include respond-to(medium-screens) {font-size:2.0vw;padding-top:15px;}
  @include respond-to(large-screens) {font-size:2.0vw;padding-top:15px;}
  @include respond-to(largest-screens) {font-size:2.0vw; padding-top:20px;}
  }
a {
  text-decoration: none !important;
}

</style>
